<template>
  <div class="main-content">
    <avue-form ref="form" :value="form" :option="formOption">
      <template slot="content">
        <FormEditor
          :details="form.content"
          @editoChange="editoChange"
          :disabled="false"
        />
      </template>

      <template slot="menuForm">
        <!-- <el-button @click="handleClose">取消</el-button> -->
        <el-button type="primary" :loading="showLoading" @click="submit(form)"
          >保存</el-button
        >
      </template>
    </avue-form>
  </div>
</template>

<script>
import FormEditor from "@/components/formComponents/formEditor";
import { aboutAddAboutUs, aboutAboutUsView } from "@/api/about/us";
export default {
  name: "createThree",
  components: {
    FormEditor,
  },
  data() {
    return {
      form: {
        content: "",
      },
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "关于我们",
            prop: "content",
            type: "text",
            span: 24,
            row: true,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              {
                validator(_, value) {
                  if (value) {
                    if (value === "<p><br></p>") {
                      return Promise.reject(new Error("请输入内容"));
                    }
                    if (value.length > 65535) {
                      return Promise.reject(new Error("内容超出最大长度限制"));
                    }
                  }
                  return Promise.resolve(true);
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    //初始化
    onLoad() {
      this.showLoading = true;
      aboutAboutUsView().then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.form = { ...res.data, content: res.data.content };
        }
      });
    },
    async setForm(form) {
      this.form = form;
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          this.showLoading = true;
          aboutAddAboutUs({
            ...this.form,
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.onLoad();
                this.showLoading = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoading = false;
                done();
              }
            })
            .catch(() => {
              this.showLoading = false;
            });
          // done()
        }
      });
    },
  },
};
</script>

<style scoped></style>
